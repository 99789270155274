import * as React from "react";
import Layout from "../components/layout";
import { styles } from "../shared-styles";

const DomainsPage = () => {
  return (
    <Layout pageTitle="Domenii de activitate">
      <section style={styles.ContentSection}>

        <section>
          <h3>DREPT CIVIL ȘI COMERCIAL</h3>
          <p>În materia dreptului civil și comercial, activitatea cabinetelor noastre constă în redactarea actelor
            procesuale (cerere de chemare în judecată, întâmpinare, cerere reconvențională, cerere de chemare în
            garanție,
            cerere de intervenție, apel, recurs, contestație în anulare, revizuire) și asistare/reprezentare în litigii.
            Cu titlu exemplificativ, enumerăm următoarele competențe:</p>
          <ul>
            <li>litigii având ca obiect apărarea drepturilor nepatrimoniale;</li>
            <li>acțiuni în revendicare, în grănițuire, posesorii, confesorii de uzufruct/superficie/servitute,
              negatorii,
              accesiune, uzucapiune, drept de trecere, depășirea inconvenientelor normale ale vecinătății;
            </li>
            <li>cereri de partaj, inclusiv partaj succesoral;</li>
            <li>acțiuni în constatare;</li>
            <li>acțiuni în rectificare de CF, în prestație tabulară;</li>
            <li>acțiuni întemeiate pe prevederile Legii nr. 10/2001,</li>
            <li>ordonanțe președințiale;</li>
            <li>ordonanțe de plată;</li>
            <li>cereri cu valoare redusă;</li>
            <li>acțiuni în constatarea calității de moștenitor și stabilirea cotelor legale de moștenire;</li>
            <li>cereri de evacuare din imobilele folosite sau ocupate fără drept;</li>
            <li>cereri privind obligația de a face sau de a nu face;</li>
            <li>acțiuni în anularea/constatarea nulității actelor juridice civile (contracte, declarații, testamente,
              donații, etc);
            </li>
            <li>acțiuni în simulație;</li>
            <li>acțiuni întemeiate pe gestiunea de afaceri, îmbogățirea fără justă cauză, plata nedatorată;</li>
            <li>răspunderea civilă contractuală, acțiuni în pretenții, acțiuni în restituire, acțiuni în
              reziliere/rezoluțiune, acțiuni oblice, acțiuni revocatorii;
            </li>
            <li>răspunderea civilă delictuală pentru fapta proprie, pentru fapta altuia, pentru prejudiciul cauzat de
              animale, de lucruri, prin ruina edificiului;
            </li>
            <li>cereri de sechestru asigurător, poprire asiguratorie și sechestru judiciar;</li>
            <li>redactare și modificare contracte, declarații, alte acte juridice;</li>
          </ul>
        </section>
        <section>
          <h3>DREPT CONTRAVENȚIONAL</h3>
          <p>În materia dreptului contravențional, cabinetele noastre prestează activități de redactare a plângerilor
            contravenționale și de reprezentare/asistare în dosare având ca obiect plângere contravențională, împotriva
            proceselor-verbale de constatare și sancționare a contravențiilor prevăzute de codul muncii (Legea nr.
            53/2003), codul rutier (O.U.G. nr. 195/2002), domeniul transporturilor (O.G. nr. 27/2011), codul silvic
            (Legea
            nr. 17/2010), domeniul contabilității (Legea nr. 82/1991), în materia autorizării lucrărilor de construcții
            (Legea nr. 50/1991).</p>
        </section>
        <section>
          <h3>DREPTUL MUNCII ȘI ASIGURĂRILOR SOCIALE</h3>
          <ul>
            <li>asistență juridică acordată societăților comerciale în privința raporturilor de munca;</li>
            <li>asistență juridică și reprezentare în litigii de dreptul muncii, conflicte de muncă individuale și
              colective, desfacerea contractelor de muncă, concedieri individuale și colective, contestație împotriva
              concedierii abuzive;
            </li>
            <li>recuperarea sumelor reprezentând salarii, drepturi din asigurări sociale, pensii, etc;</li>
            <li>acțiuni în constatare privind condițiile speciale de muncă;</li>
            <li>acțiuni privind reconstituirea vechimii în muncă, întocmire, evaluare, redactare contracte individuale
              de
              muncă și acte adiționale, clauze speciale de confidențialitate, de neconcurență, etc;
            </li>
            <li>asistență juridică în procedura desfacerii contractelor de muncă;</li>
            <li>asistență juridica și reprezentare acordată salariaților pentru neplata drepturilor salariale, pagube
              materiale izvorâte din raporturile de muncă;
            </li>
          </ul>
        </section>
        <section>
          <h3>DREPTUL FAMILIEI</h3>
          <ul>
            <li>divorț, obligarea soțului pârât la plata prestației compensatorii și/sau la plata de despăgubiri,
              obligația de întreținere între foști soți;
            </li>
            <li>stabilirea autorității părintești, stabilirea locuinței minorilor, stabilirea pensiei de întreținere;
            </li>
            <li>atribuirea locuinței familiale;</li>
            <li>partajul în timpul regimului comunității;</li>
            <li>partajul bunurilor comune;</li>
            <li>acțiuni în contestarea filiației, în stabilirea filiației, în stabilirea paternității, în tăgada
              paternității;
            </li>
            <li>ordin de protecție;</li>
          </ul>
        </section>
        <section>
          <h3>FOND FUNCIAR</h3>
          <ul>
            <li>litigii având ca obiect reconstituirea dreptului de proprietate în baza Legii 18/1991;</li>
            <li>acțiuni în rectificare de CF, anulare /modificare titlu de proprietate;</li>
            <li>plângeri împotriva hotărârilor Comisiei Județene sau a Comisiilor Locale pentru aplicarea Legilor
              Fondului
              Funciar;
            </li>
            <li>acțiuni de partaj terenuri;</li>
            <li>asistarea/reprezentarea părților în demersurile administrative efectuate în vederea reconstituirii
              dreptului de proprietate, în relația cu Comisiile Locale, Comisia Județeană de fond funciar, ANRP,
              Prefectură;
            </li>
          </ul>
        </section>
        <section>
          <h3>EXECUTARE SILITĂ</h3>
          <ul>
            <li>formularea cererilor de executare silită;</li>
            <li>formularea unor cereri pe parcursul derulării executării silite (cerere de înființare poprire, cerere de
              intervenție, cerere de sechestrare a bunurilor mobile, cerere de încuviințare a urmăririi imobiliare,
              obiecțiuni și contestație la proiectul de distribuire);
            </li>
            <li>formularea contestației la executare, a cererilor de suspendare și suspendare provizorie a executării
              silite;
            </li>
            <li>cerere de întoarcere a executării;</li>
          </ul>
        </section>
        <section>
          <h3>CONTENCIOS ADMINISTRATIV ȘI FISCAL</h3>
          <ul>
            <li>acțiuni în anularea actelor administrative;</li>
            <li>suspendarea actelor administrative;</li>
          </ul>
        </section>
        <section>
          <h3>CURTEA EUROPEANĂ A DREPTURILOR OMULUI (CEDO)</h3>
          <ul>
            <li>consultanță si redactare plângeri adresate Curții Europene a Drepturilor Omului (CEDO).</li>
          </ul>
        </section>
      </section>
    </Layout>
  );
};

export default DomainsPage;
